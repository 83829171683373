<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" custom-class="custom-dialog-max-width" @closed="form = null; auditRemark = '';auditStore = null;">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <div class="h">
            <el-form-item prop="outWarehouseId" label="发货库房">
              <quick-select v-model="form.outWarehouseId" :label.sync="form.outWarehouseName" url="api/warehouse/all" placeholder="选择发货库房" clearable filterable class="filter-item" :disabled-option="o => { return o.id === form.inWarehouseId }" @change="handleWarehouseChange" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="inWarehouseId" label="收货库房" label-width="100px">
              <quick-select v-model="form.inWarehouseId" :label.sync="form.inWarehouseName" url="api/warehouse/all" placeholder="选择收货库房" clearable filterable class="filter-item" :disabled-option="o => { return o.id === form.outWarehouseId }" @change="handleWarehouseChange" style="width: 180px;" />
            </el-form-item>
            <el-form-item label-width="10px" v-if="form.outWarehouseId && form.inWarehouseId">
              <el-tooltip content="交换发货/收货库房">
                <el-button type="info" @click="handleWarehouseExchange">
                  <i class="fas fa-exchange-alt"></i>
                </el-button>
              </el-tooltip>
            </el-form-item>
          </div>
          <el-form-item label="调拨明细" required>
            <sku-selector request-url="api/warehouseGoods" :request-params="{warehouseIds: [form.outWarehouseId],minCount:0.01}" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" v-if="form.outWarehouseId" />
            <div class="fc-g" v-else>请先选择发货库房</div>
            <!-- <datagrid-picker reference="添加商品" reference-type="primary" url="api/shop/salesGoods" value-field="goodsId" :exclude-keys="excludeGoods" :params="{entId: form.sellerId}" :query-define="goodsQueryDefine" :popper-width="960" :show-index="false" multiple submit>
              <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                <el-input :maxlength="10" v-model="goodsQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入商品名称进行搜索" style="width: 240px;">
                  <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                </el-input>
              </div>
              <el-table-column prop="name" label="商品名称" min-width="200" />
              <el-table-column label="规格" min-width="120">
                <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs)}}</template>
              </el-table-column>
              <el-table-column prop="brandName" label="品牌" width="120" />
              <el-table-column prop="seriesName" label="系列" width="120" show-overflow-tooltip />
            </datagrid-picker>-->
          </el-form-item>
          <el-form-item label-width="0" prop="items">
            <el-table :data="form.items" empty-text="没有要调拨的商品" :row-class-name="handleDetailRowClass" class="table-outdent">
              <el-table-column prop="goodsCode" label="商品编码" min-width="130" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
              <el-table-column prop="goodsName" label="商品名称" min-width="240" />
              <el-table-column prop="goodsSpec" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
              <el-table-column prop="goodsBrandName" label="品牌" width="120" />
              <el-table-column label="发货库房库存" align="right" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row._stockInfo.loading" class="fc-g">正在获取中…</div>
                  <div v-else-if="scope.row._stockInfo.error" class="h c">
                    <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <div v-else>{{scope.row._stockInfo.total.out}}</div>
                </template>
              </el-table-column>
              <el-table-column label="收货库房库存" align="right" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row._stockInfo.loading" class="fc-g">正在获取中…</div>
                  <div v-else-if="scope.row._stockInfo.error" class="h c">
                    <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <div v-else>{{scope.row._stockInfo.total.in}}</div>
                </template>
              </el-table-column>
              <el-table-column label="调拨数量" width="100">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.count" @change="$checkNaN(scope.row,'count',0)" :disabled="scope.row._stockInfo.error || scope.row._stockInfo.loading" :min="0" :max="scope.row._stockInfo.error || scope.row._stockInfo.loading ? Infinity : scope.row._stockInfo.total.out" :step="1" :precision="2" size="mini" controls-position="right" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="备注" width="180">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
              <el-table-column width="50">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="2" resize="none" />
          </el-form-item>
          <el-divider />
          <el-popover trigger="hover" placement="top-start" v-if="auditStore && auditStore.length">
            <el-table :data="auditStore" height="400">
              <el-table-column label="操作时间" :formatter="r => {return new Date(r.auditTime).format(); }" width="150" />
              <el-table-column label="操作人" prop="auditByName" width="120" />
              <el-table-column label="操作类型" width="100">
                <template slot-scope="scope">{{auditTypes[scope.row.auditType]}}</template>
              </el-table-column>
            </el-table>
            <el-button type="text" slot="reference">查看审核记录</el-button>
          </el-popover>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="80px" label-suffix=":" label-position="right">
          <div class="h s sb">
            <div>
              <form-info-item label="调拨单号">{{form.formCode}}</form-info-item>
              <form-info-item label="调拨日期">{{new Date(form.createAt).format("yyyy/MM/dd")}}</form-info-item>
            </div>
            <div>
              <form-info-item label="发货库房">{{form.outWarehouseName}}</form-info-item>
              <form-info-item label="收货库房">{{form.inWarehouseName}}</form-info-item>
            </div>
          </div>
          <el-table border :data="form.items" highlight-current-row empty-text="没有要发货的商品" style="margin: 8px 0;">
            <el-table-column prop="goodsCode" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="goodsName" label="商品名称" min-width="200" />
            <el-table-column prop="goodsSpec" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
            <el-table-column prop="goodsBrandName" label="品牌" width="120" />
            <el-table-column prop="count" label="调拨数量" align="right" width="80" />
            <el-table-column prop="info" label="备注" minwidth="120" />
          </el-table>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          <el-divider />
          <div class="h c">
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <template v-if="!auditable">
              <form-info-item label="收货人">{{form.receiver}}</form-info-item>
              <form-info-item label="联系方式">{{form.receiverPhone}}</form-info-item>
              <form-info-item label="收货时间">{{form.receiveTime?new Date(form.receiveTime).format("yyyy/MM/dd"):''}}</form-info-item>
              <form-info-item label="创建时间">{{new Date(form.createAt).format("yyyy/MM/dd hh:mm:ss")+'('+form.createBy+')'}}</form-info-item>
              <form-info-item label="修改时间" v-if="form.editAt">{{form.editAt?new Date(form.editAt).format("yyyy/MM/dd hh:mm:ss")+'('+form.editBy+')':''}}</form-info-item>
              <form-info-item label="审批时间">{{form.checkTime?new Date(form.checkTime).format("yyyy/MM/dd hh:mm:ss")+'('+form.checkerName+')':''}}</form-info-item>
            </template>

            <el-popover trigger="hover" placement="top-start" v-if="auditStore && auditStore.length">
              <el-table :data="auditStore" height="400">
                <el-table-column label="操作时间" :formatter="r => {return new Date(r.auditTime).format(); }" width="150" />
                <el-table-column label="操作人" prop="auditByName" width="120" />
                <el-table-column label="操作类型" width="100">
                  <template slot-scope="scope">{{auditTypes[scope.row.auditType]}}</template>
                </el-table-column>
              </el-table>
              <el-button type="text" slot="reference">查看审核记录</el-button>
            </el-popover>
            <div class="flex"></div>
            <template v-if="auditable">
              <el-input v-model="form.receiver" :maxlength="50" placeholder="请填写收货人" style="width: 130px;" />&emsp;
              <el-input v-model="form.receiverPhone" :maxlength="20" placeholder="请填写联系方式" style="width: 130px;" />&emsp;
              <el-date-picker v-model="form.receiveTime" placeholder="请选择收货时间" value-format="timestamp" style="width: 160px;" />&emsp;
              <el-input v-model="auditRemark" :maxlength="100" placeholder="请填写审批意见" style="width: 320px;" />&emsp;
              <el-button type="primary" :loading="auditing" @click="doAudit(true)">同意</el-button>
              <el-button type="danger" :loading="auditing" @click="doAudit(false)">拒绝</el-button>
            </template>
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { get, add, edit, audit } from "@/api/allocation";
import { getGoodsById } from "@/api/warehouse";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
export default {
  components: { skuSelector },
  data() {
    return {
      loading: false,
      saving: false,
      submiting: false,
      dialog: false,
      form: null,
      auditing: false,
      auditable: false,
      auditRemark: "",
      auditStore: null,
      goodsQueryDefine: {
        name: "",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已通过", type: "primary" },
        { label: "已拒绝", type: "danger" },
        { label: "已发货", type: "warning" },
        { label: "已完成", type: "success" },
      ],
      auditTypes: {
        pass: "审核通过",
        refuse: "审核未通过",
        rollback: "反审核",
      },
      rules: {
        outWarehouseId: [{ required: true, message: "发货库房为必填项" }],
        inWarehouseId: [{ required: true, message: "收货库房为必填项" }],
        items: [
          { required: true, message: "调拨明细为必填项" },
          { type: "array", min: 1, message: "调拨明细为必填项" },
        ],
      },
    };
  },
  computed: {
    title() {
      if (this.loading) {
        return "加载中…";
      } else if (this.form) {
        if (this.editable) {
          return this.form.id?"编辑调拨单":"新增调拨单";
        } else if (this.auditable) {
          return "审批调拨单";
        } else {
          return "查看调拨单";
        }
      }
      return "";
    },
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    editable() {
      return (
        !this.auditable &&
        this.form &&
        (this.form.status === 0 || this.form.status === 2)
      );
    },
  },
  methods: {
    handleDetailRowClass(e) {
      if (e.row._stock != null && e.row._stock < e.row.count)
        return "el-table-warning-row";
    },
    handleWarehouseChange() {
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          this.loadStockInfo(o);
        });
      }
    },
    handleWarehouseExchange() {
      let t = this.form.outWarehouseId;
      this.form.outWarehouseId = this.form.inWarehouseId;
      this.form.inWarehouseId = t;
      this.handleWarehouseChange();
    },
    loadStockInfo(g) {
      if (g && g.goodsId) {
        g._stockInfo.error = false;
        let ws = [];
        if (this.form.outWarehouseId) ws.push(this.form.outWarehouseId);
        if (this.form.inWarehouseId) ws.push(this.form.inWarehouseId);
        if (ws && ws.length) {
          g._stockInfo.loading = true;
          getGoodsById(g.goodsId, ws)
            .then((res) => {
              let oc = 0,
                ic = 0;
              (res || []).forEach((ro) => {
                if (ro.warehouseId === this.form.outWarehouseId) oc += ro.count;
                if (ro.warehouseId === this.form.inWarehouseId) ic += ro.count;
              });
              if (this.editable && g.count > oc) g.count = oc;
              g._stockInfo.total = {
                out: oc,
                in: ic,
              };
            })
            .catch((err) => {
              g._stockInfo.error = err.message || "获取库存信息失败";
            })
            .finally((_) => {
              g._stockInfo.loading = false;
            });
        } else {
          g._stockInfo.total = {
            out: 0,
            in: 0,
          };
        }
      }
    },
    doAudit(pass) {
      this.auditing = true;
      audit({
        id: this.form.id,
        receiver: this.form.receiver,
        receiverPhone: this.form.receiverPhone,
        receiveTime: this.form.receiveTime,
        checkInfo: this.auditRemark,
        pass: pass,
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "审批调拨单成功!",
          });
          this.dialog = false;
          this.$parent.init();
        })
        .finally((_) => {
          this.auditing = false;
        });
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          let err = false;
          for (let i = 0, l = this.form.items.length; i < l; i++) {
            let o = this.form.items[i];
            if (o._stockInfo.total.out <= 0) {
              this.$alert(`商品【${o.goodsName}】的库存不足。`, "系统提示");
              err = true;
              break;
            }
            if (o.count <= 0) {
              this.$alert(
                `商品【${o.goodsName}】的调拨数量必须大于0。`,
                "系统提示"
              );
              err = true;
              break;
            }
          }
          if (!err) {
            this.saving = true;
            let isAdd = !this.form.id;
            (isAdd ? add : edit)(this.form)
              .then((res) => {
                if (isAdd) this.form.id = res.id;
                this.$notify({
                  title: `保存调拨单成功`,
                  type: "success",
                  duration: 2500,
                });
                this.$parent.init();
              })
              .finally((_) => {
                this.saving = false;
              });
          }
        });
    },
    doPrint() {
      if (this.form && this.form.id) this.$parent.print(this.form.id);
    },
    resetForm(form = null, auditable = false) {
      this.auditable = auditable;
      if (form && form.id) {
        get(form.id).then((res) => {
          res.items.forEach((o) => {
            o._id = this.$uuid();
            o._stockInfo = {
              loading: false,
              error: false,
              total: {
                out: o.count,
                in: 0,
              },
            };
          });
          this.form = res;
          this.handleWarehouseChange(this.form.warehouseId);
          this.getAuditInfo();
        });
      } else {
        // let now = new Date();
        this.form = Object.assign(
          {
            outWarehouseId: null,
            outWarehouseName: null,
            inWarehouseId: null,
            inWarehouseName: null,
            info: "",
            status: 0,
            items: [],
          },
          form || {}
        );
      }
      this.dialog = true;
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach((o) => {
          let _no = {
            _id: this.$uuid(),
            code: o.code,
            erpCode: o.erpCode,
            barcode: o.code,
            goodsId: o.id,
            goodsCode: o.code,
            goodsBrandId: o.brandId,
            goodsBrandName: o.brandName,
            goodsSeriesId: o.seriesId,
            goodsSeriesName: o.seriesName,
            goodsName: o.name,
            goodsSpec: o.specs,
            count: 1,
            info: "",
            _stockInfo: {
              loading: false,
              error: false,
              total: {
                out: 0,
                in: 0,
              },
            },
          };
          ds.push(_no);
          this.loadStockInfo(_no);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o._id === row._id;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
    getAuditInfo() {
      if (this.form && this.form.id) {
        request({
          url: "api/allocationForm/auditRecord/list",
          method: "get",
          params: {
            id: this.form.id,
          },
        }).then((res) => {
          this.auditStore = res;
        });
      }
    },
  },
};
</script>
